import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Web Developer Irvine CA Case Study - Ivantis - Websuasion"
        ogDescription="Are you looking for a professional web developer serving the Irvine, CA area? See how we enabled Ivantis to achieve their business goals."
        image="/images/case-studies/web-design-pinewood-atlanta-studios.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Web Developer Irvine CA</h1>
						<h3>A Case Study of Ivantis</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled Ivantis to achieve their web portal development goals by providing ReactJS and server side programming for the most robust Glaucoma Surgery Registry Software of its kind.
            </p>
						<p>
							Are you looking for a professional web developer serving the Irvine CA area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/irvine-ca-application-development-ivantis.jpg" className="rounded-image" alt="Web Developer Irvine CA Case Study - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/ivantis-logo.webp" alt="Web Developer Irvine CA Case Study - Websuasion" />
							</p>
			        <p>
								Ivantis, Inc. is a company dedicated to the development of new and innovative solutions for glaucoma. Current therapies for primary open angle glaucoma (POAG) include eye drops, laser therapy, and surgery. But each of these has its limitations. Glaucoma remains the leading cause of blindness worldwide, in need of better treatment alternatives. It is our goal to develop new and effective solutions for ophthalmology specialists and the 70 million people worldwide who are affected by this debilitating and sight-stealing disease.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Ivantis Inc. utilizes the services of The Websuasion Group on an ongoing basis for our cloud-based data portal services, which allows practices to provide vital surgical and clinical data for their glaucoma patients. Ryan and his team designed the system architecture, developed the portals, generated data visualization, maintain our servers and provide analytical services. They are responsive and knowledgeable with regard to our complex data and reporting needs. They have been a big asset to Ivantis and allow us to leverage our data in ways we never expected.</Blockquote>
							<p>
							- Lauren Ries, Strategic Account Manager
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/ivantis-lauren-ries.jpg" className="rounded-square" alt="Web Developer Irvine CA - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Worldwide, secure collection of glaucoma surgery information</li>
			        <li>Validation of complex data entry requirements</li>
							<li>Detailed aggregated reporting on surgery results</li>
			        <li>Legacy data integrity verification and cleaning</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Custom practice portal for surgical data entry</li>
			        <li>Custom administration portal for extensive reporting and data visualization</li>
							<li>Data entry validation integrated with guidance and integrity checking</li>
			        <li>In-depth evaluation of legacy data and flagging of potential issues</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Application Architecture</h2>
							<p>
								Ivantis approached us in 2015 to develop application architecture for, and subsequently build, a web administration application, and corresponding web medical practice portal. We started the process with a highly detailed and iterative application architecture phase. Over the course of several meetings, we compiled a detailed list of specifications from which we generated process workflows and screen mockups for each part of the system. The specifications included the central API structure, database schema, object modeling, and internal business logic. From the final application architecture document, we were able to provide a precise timeline and cost for the system.
							</p>
						<p>
							<img src="/images/case-studies/application-architecture.jpg" alt="Web Developer Irvine CA  - Websuasion" />
						</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Spectrum Web Application</h2>
							<p>
								Ivantis are developers of a ground-breaking device, Hydrus, designed to treat severe glaucoma. Ivantis needed a system that could not only be shared across many medical practice locations worldwide, but could also manage vast amounts of complex data about Hydrus surgeries. To accomplish this task, we designed a robust portal that collects anonymous statistical data from the practices performing the surgical procedures. Extensive data reporting and graphing options are available to both the doctors and Ivantis. The resulting Spectrum portal has become a centerpiece of their organization's efforts in developing peer reviewed medical studies and gaining detailed understanding for which types of patients Hydrus is best suited.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								On a continual basis, we provide server maintenance, data-integrity assistance, and hosting of the portals.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
